import Component from './Component';
import ComponentContainer from './ComponentContainer';

export default class VenveoApplication {
    private $registry: { [index: string]: ComponentContainer };
    private $observedHandles: string[];
    private $observer: any;

    constructor() {
        this.$registry = {};
        this.$observedHandles = [];
        this.$observer = null;
    }

    /**
     *
     * @param handle
     * @param selector
     * @param component
     * @param options
     */
    registerComponent(handle: string, selector: string | null, component: Component | string, options: object = {}): ComponentContainer {
        let $element = null
        if (typeof selector === 'string') {
            $element = document.querySelector(selector)
        }

        let resolvedComponent: Component | null = null

        let deferred = false
        let lazy = false
        let componentPath = null

        if (typeof component === 'string') {
            console.log('Got a component path')
            componentPath = component
            deferred = true
        } else {
            resolvedComponent = component
        }

        if ($element && $element.classList.contains('lazy-component')) {
            lazy = true
        }

        const container = new ComponentContainer({
            handle: handle,
            $element: $element,
            component: resolvedComponent,
            componentPath: componentPath,
            deferred: deferred,
            lazy: lazy,
            options: options,
            $application: this
        })

        return this.$registry[handle] = container
    }

    // _addToObserver(componentRegistration) {
    //     console.log('Added to observer')
    //     this.$observedHandles.push(componentRegistration.handle)
    // }

    // _initObserver() {
    //     let components = []
    //     this.$observedHandles.forEach(handle => components.push(this.$registry[handle]))
    //
    //     const elements = document.querySelectorAll('.lazy-component')
    //     this.$observer = new IntersectionObserver(entries => {
    //         entries.forEach(entry => {
    //             if (entry.intersectionRatio > 0) {
    //                 const target = entry.target
    //                 components.forEach((c) => {
    //                     if (c.$element === target) {
    //                         if (!c.instance) {
    //                             if (c.deferred === false) {
    //                                 c.init();
    //                                 console.log('Lazy: Initialized deferred component', c)
    //                             } else {
    //                                 c.asyncSetup().then((e) => {
    //                                     c.init();
    //                                     console.log('Lazy: Loaded & initialized deferred component', c)
    //                                 });
    //                             }
    //                         }
    //                     }
    //                 })
    //             }
    //         })
    //     })
    //     elements.forEach(element => this.$observer.observe(element))
    // }

    async init() {
        // Iterate over all component in the registry
        for (const [handle, componentRegistration] of Object.entries(this.$registry)) {
            console.log('Attempting to init', handle)
            // Element not present on page
            if (!componentRegistration.ableToInitialize()) {
                console.warn('Element not present on page')
                continue
            }


            if (componentRegistration.isDeferred()) {
                //     if (componentRegistration.lazy) {
                //         this._addToObserver(componentRegistration)
                //         console.log('Added lazy-deferred component to observer')
                //     } else {
                        componentRegistration.asyncSetup().then(() => {
                            componentRegistration.init()
                            console.debug('Initialized deferred component', componentRegistration)
                        });
                //     }
                //     continue;
                // } else {
                //     console.log('Setting up', componentRegistration)
                //     componentRegistration.setup()
                // }
                // componentRegistration.init()
            } else {
                componentRegistration.setup()
                componentRegistration.init()
            }

            // this._initObserver();
        }
    }
}