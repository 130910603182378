import Alpine from 'alpinejs'
import 'lazysizes'
import './utils/currency'
import GLightbox from 'glightbox'

import MmenuLight from './components/ui/Mmenu'
import VenveoApplication from "./base/VenveoApplication"
import ProductDetailPage from "./components/ProductDetailPage";
import ProductDetailPageV2 from "./components/ProductDetailPageV2";
import Cart from "./components/Cart";
import FeaturedResource from './components/ui/sliders/FeaturedResource'
import TableSlider from './components/ui/sliders/TabelSlider'
import GallerySlider from './components/ui/sliders/GallerySlider'
import LocatorActionButton from './components/ui/LocatorActionButton'
import BasementInteractive from './components/BasementInteractive'
const application = new VenveoApplication();

window.Alpine = Alpine
window.lightbox = GLightbox()
window.GLightbox = GLightbox;

application.registerComponent('mmenu-light', '#mobile-menu-nav', MmenuLight)
application.registerComponent('featured-resource-slider', '[data-featresource-slider]', FeaturedResource)
application.registerComponent('product-detail', '[data-product-detail-page]', ProductDetailPage)
application.registerComponent('product-detail-V2', '[data-product-detail-page-v2]', ProductDetailPageV2)
application.registerComponent('shopping-cart', '[data-shopify]', Cart)
application.registerComponent('featured-table-slider', '[data-table-slider]', TableSlider)
application.registerComponent('featured-gallery-slider', '[data-gallery-slider]', GallerySlider)
application.registerComponent('locator-action-button', '[locator-form]', LocatorActionButton)
application.registerComponent('basement-interactive', '[basement-interactive]', BasementInteractive)

application.init().then(() => {
    console.log('Site is ready.')
    Alpine.start()
})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
