import Component from "./Component";
import VenveoApplication from "./VenveoApplication";

type ComponentContainerConfig = {
    handle: string;
    $element: Element | null;
    component: Component | null;
    componentPath: string | null;
    deferred: boolean;
    lazy: boolean;
    options: object;
    $application: VenveoApplication;
}

export default class ComponentContainer {
    private config: ComponentContainerConfig;
    private instance: Component | null;

    constructor(config: ComponentContainerConfig) {
        this.config = config;
        this.instance = null;
    }

    async asyncSetup(): Promise<void> {
        console.log('About to import', this)
        const component = await import(
            /* webpackMode: "lazy" */
            /* webpackPreload: true */
            `../components/${this.config.componentPath}`
            );
        this.config.component = component;
        this.instance = new component.default(this.config.options)
    }

    setup(): void {
        if (this.config.component === null) {
            console.error('Could not initialize null component.')
            return;
        }
        // @ts-ignore
        this.instance = new this.config.component(this.config.options)
        console.log('[APP] SETUP', this);
    }

    init(): void {
        if (this.instance) {
            this.instance.init();
            console.log('[APP] INIT', this);
        } else {
            console.error('Init called before setup')
        }
    }

    ableToInitialize(): boolean {
        return !!this.config.$element
    }

    isDeferred(): boolean {
        return this.config.deferred
    }
}