declare global {
    interface SizeOption extends Object {
        name: string | null,
        id: string,
        size: string,
        productId: string,
        position: number,
        productPhoto: string,
        shopifyVariant: object
    }

    interface ProductData extends Object {
        slug: string,
        type: string,
        name: string,
        swatchImage: string | null,
        sizeOptions: Record<string | number, SizeOption>
    }

    interface Window {
        productData: Record<string | number, Array<ProductData>>
        defaultProductImage: string;
        selectedModel: string | null;
    }
}

// @ts-ignore
import Alpine from 'alpinejs'

import Component from '../base/Component';
import {gtmEcomm} from "../utils/gtmEcomm";

export default class ProductDetailPage extends Component {
    init() {
        this.initStore()
    }

    async initStore() {

        const productPageData = window.productDataArr;
        //const productPageDataTypes = Object.keys(window.productDataArr);

        // This will be set to the pages' query parameter for "model"
        let selectedModel = window.selectedModel;

        Alpine.store('productpage', {
            $allProductsData: productPageData,
            $singleProductData: null,
            $selectedProduct: null,
            $activeTab: 1,
            $selectedProductDataTypes: null,
            $selectedItem: null,
            $selectedSize: null,
            $shopifyVariant: null,
            qty: 1,

            switchTab(tab) {
                this.$activeTab = tab;

                let select = null;
                switch (tab) {
                    case 1:
                        select = document.getElementById('v2-decking-prod');
                        break;
                    case 2:
                        select = document.getElementById('v2-siding-prod');
                        break;
                }

                if (select && select?.value) {
                    this.switchSelectedProduct(select.value);
                }
            },

            switchSelectedProduct(productSlug: string) {
                this.$singleProductData = productPageData[productSlug];
                this.$selectedProduct = productSlug;
                this.$selectedProductDataTypes = Object.keys(this.$singleProductData);
                this.init();

                const sizeSelect = document.querySelectorAll('.product-size-select');
                const sizes = this.$selectedItem.sizeOptions;

                sizeSelect.forEach(item => {
                    item.options.length = 0
                    for (const prop in sizes) {
                        let option = document.createElement('option');
                        option.value = prop;
                        option.text = sizes[prop].name;
                        item.appendChild(option);
                    }
                })
            },

            /**
             * Searches for product variants based on a given product ID
             *
             * This should probably be memoized
             * @param productId
             */
            getProductDetailsByProductId(productId: string) {
                let results = null

                productPageDataTypes.forEach(type => {
                    const productsByType = productPageData[type]
                    productsByType.forEach(product => {
                        const sizeOptions = product.sizeOptions;
                        const sizeOptionsKeys = Object.keys(sizeOptions);
                        sizeOptionsKeys.forEach(key => {
                            const size = sizeOptions[key];
                            if (size.productId && parseInt(productId) === parseInt(size.productId) || size.id && parseInt(productId) === parseInt(size.id)) {
                                results = {
                                    productType: type,
                                    product: product,
                                    size: size
                                }
                            }
                        });
                    })
                })

                return results
            },

            /**
             * Updates the current product and size
             * @param product
             * @param size
             * @param shouldPush
             */
            updateProductAndSize(product: ProductData | null, size: string | null, shouldPush = false) {
                if (product) {
                    this.$selectedItem = product
                }

                if (size) {
                    this.$selectedSize = size
                }
                // if (shouldPush) {
                //     this.updateQueryParam()
                // }
            },

            /**
             * Set the default product on page load (first item)
             */
            init() {
                if (productPageData) {
                    let productDetails = null
                    if (selectedModel) {
                        productDetails = this.getProductDetailsByProductId(selectedModel)
                    }
                    if (productDetails) {
                        this.updateProductAndSize(productDetails.product, productDetails.size.size, false)
                    } else {
                        if (this.$selectedProduct) {
                            // Fallback to first item
                            this.updateProductAndSize(productPageData[this.$selectedProduct][this.$selectedProductDataTypes[0]][0], null, false)
                        }
                    }
                } else {
                    console.error('Unable to determine a default product!')
                }
            },

            /**
             * Update the selected product
             * @param type The product type
             * @param slug The variant slug
             */
            updateSelectedItem(el) {
                const type = el.getAttribute('data-vtype');
                const slug = el.getAttribute('data-vslug');
                const productGroup = this.$singleProductData[type]

                if (!productGroup) {
                    console.error('Invalid product group selected', type)
                    return;
                }
                const product = this.$singleProductData[type].find((p: ProductData) => p.slug === slug)
                if (!product) {
                    console.error('Product does not exist in group', {slug, type})
                    return;
                }
                const sizeOptions = Object.keys(product.sizeOptions);
                let size = null
                const sizeSelect = document.querySelectorAll('.select-size-group');
                if (sizeOptions.length) {
                    if (sizeOptions.includes(this.$selectedSize)) {
                        size = this.$selectedSize
                    } else {
                        size = product.sizeOptions[sizeOptions[0]].size;
                    }
                    sizeSelect.forEach(item => {
                        item.style.display = 'block';
                    })
                } else {
                    sizeSelect.forEach(item => {
                        item.style.display = 'none';
                    })
                }

                this.updateProductAndSize(product, size, true)
                // this.selectedItem = product;
                console.debug('Selected Item Updated', this.selectedItem)
            },

            updateSelectedSize(size: string) {
                this.updateProductAndSize(null, size, true)
            },

            /**
             * Getter for the selected product size. Ensures the size selected
             * exists on the current product, if not select the first option
             */
            get selectedSize() {
                if (!this.selectedItem) {
                    return null;
                }

                const sizeOptions = Object.keys(this.selectedItem.sizeOptions);
                if (sizeOptions.length) {
                    if (sizeOptions.includes(this.$selectedSize)) {
                        return this.$selectedSize
                    } else {
                        return this.selectedItem.sizeOptions[sizeOptions[0]].size;
                    }
                }
            },

            get selectedSizeObject() {
                if (!this.selectedSize) {
                    return null;
                }

                const sizeOptions = Object.keys(this.selectedItem.sizeOptions);
                const indexOfSize = sizeOptions.indexOf(this.selectedSize)
                return this.selectedItem.sizeOptions[indexOfSize]
            },

            set selectedSize(value) {
                this.$selectedSize = value;
            },

            get selectedItem() {
                return this.$selectedItem
            },

            set selectedItem(value) {
                this.$selectedItem = value;
            },

            set shopifyVariant(value) {
                this.$shopifyVariant = value;
            },

            /**
             * Get the active Shopify variant data based on the selected options
             */
            get shopifyVariant() {
                if (!this.selectedSize || !Object.keys(this.selectedItem.sizeOptions).length) {
                    return null
                }

                return this.selectedItem.sizeOptions[this.selectedSize].shopifyVariant ?? null;
            },

            /**
             * Get the current product image based on selected options.
             * Allows for specific sizes to have their own images or to fallback to the product default
             *
             */
            get currentProductImage() {
                if (this.selectedItem) {
                    const selectedSize = this.selectedItem.sizeOptions[this.selectedSize];
                    if (selectedSize && selectedSize.productPhoto) {
                        return selectedSize.productPhoto
                    }
                }
                return window.defaultProductImage;
            },

            updateQueryParam() {
                const selectedSize = this.selectedSize;
                const selectedSizeObject = this.selectedItem.sizeOptions[selectedSize];

                const productId = selectedSizeObject.productId ?? selectedSizeObject.id;
                if ('URLSearchParams' in window) {
                    var searchParams = new URLSearchParams(window.location.search)
                    searchParams.set("model", productId);
                    var newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
                    history.pushState({
                        productId: productId
                    }, '', newRelativePathQuery);
                }
                if (selectedSizeObject.productId) {
                    document.querySelectorAll('[data-bv-product-id]').forEach(function ($el) {
                        $el.dataset.bvProductId = selectedSizeObject.productId;
                        console.log('Updated product id on bv item', $el)
                    });
                }
            }
        })

        /**
         * When the selected Shopify variant changes, send the Shopify product ID to GTM
         */
        // Alpine.effect(() => {
        //     // Record the GTM event
        //     const variant = Alpine.store('productpage').shopifyVariant
        //     if (variant) {
        //         gtmEcomm.viewProductDetails(variant.id)
        //     }
        // })

        // let pid = 6312
        // let productDetails = null
        // if (pid) {
        //     productDetails = Alpine.store('productpage').getProductDetailsByProductId(pid)
        // }
        //
        // if (productDetails) {
        //     Alpine.store('productpage').updateProductAndSize(productDetails.product, productDetails.size.size, false)
        // }

    }

}