import {
  Swiper,
  Navigation,
  A11y,
  Thumbs
} from 'swiper/swiper.esm.js';

Swiper.use([Navigation, A11y, Thumbs]);

import Component from "../../../base/Component";

export default class GallerySlider extends Component {
  constructor(props) {
      super(props)
      this.slidersWrap = document.querySelector('[data-gallery-slider]');
  }

  init() {
    const thumbsSlider = this.slidersWrap.querySelector('.thumbs-gallery-slider')
    const thumbsGallerySlider = new Swiper(thumbsSlider, {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: {
        1024: {
          spaceBetween: 20,
          slidesPerView: 5,
        }
      },
    });
    const gallerySlider = new Swiper(this.slidersWrap.querySelector('.gallery-slider'), {
      spaceBetween: 10,
      autoHeight: true,
      navigation: {
        nextEl: ".thumbs-gallery-slider-button-next",
        prevEl: ".thumbs-gallery-slider-button-prev",
      },
      thumbs: {
        swiper: thumbsGallerySlider,
      }
    });

    const slideCount = thumbsSlider.querySelectorAll('.swiper-slide').length

    if(innerWidth < 1024 && slideCount < 5 || innerWidth >= 1024 && slideCount < 6) {
      this.slidersWrap.classList.add('no-pagination')
    }
  }
}

