import {
    Swiper,
    Navigation,
    A11y
} from 'swiper/swiper.esm.js';

Swiper.use([Navigation, A11y]);

import Component from "../../../base/Component";

export default class TableSlider extends Component {
    constructor(props) {
        super(props)
        this.slider = document.querySelector('[data-table-slider]');
    }

    init() {
        const swiper = new Swiper(this.slider.querySelector('.swiper-container'), {
            touchRatio: 1,
            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlidesBounds: true,
            centerInsufficientSlides: true,
            centeredSlides: false,
            initialSlide: 0,
            loop: true,
            navigation: {
                nextEl: '.featresource-slider__control-next',
                prevEl: '.featresource-slider__control-prev',
            },
            // Responsive breakpoints
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                }
            },

            on: {
                resize: function(swiper) {
                    if (window.innerWidth >= 767) {
                        setTimeout(() => {
                            swiper.slideNext()
                        }, 300)
                    }
                },
                slideNextTransitionStart:  function(swiper) {
                    setHeight(swiper.el)
                },
                slidePrevTransitionStart:  function(swiper) {
                    setHeight(swiper.el)
                },
            },
        });

        let firstLoad = true
        document.addEventListener('DOMContentLoaded', () => {
            if (window.innerWidth < 768 && firstLoad) {
                setTimeout(() => {
                    setHeight(swiper.el)
                    firstLoad = false
                }, 300)

            }
        })

        function setHeight(el) {
            let activeSlide = el.querySelector('.swiper-slide-active')
            let table = document.querySelector('.table-mobile')
            let leftColumns = [...table.querySelectorAll('.left-column-block')]
            let rightColumns = [...activeSlide.querySelectorAll('.right-column')]

            leftColumns.forEach((col, idx) => {
                if(col.dataset.header === rightColumns[idx]?.dataset?.header) {
                    col.style.height = rightColumns[idx].offsetHeight + 'px'
                    if(idx !== 0) rightColumns[idx].classList.add(col.dataset.color)
                } else {
                    rightColumns[idx-1].insertAdjacentHTML("afterEnd", `<div class="${col.dataset.color} right-column" style="height:${col.offsetHeight}px" data-header="${col.dataset.header}"></div>`);
                    rightColumns = [...activeSlide.querySelectorAll('.right-column')]
                }
            })
        }
    }

}

