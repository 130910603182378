const uglCurrency = {
    format: function(value: any) {
        return Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(value);
    }
};

window.uglCurrency = uglCurrency;