import { EOL } from "os";
import Component from "../base/Component";

export default class BasementInteractive extends Component {
  constructor(props) {
    super(props);
    this.interactiveBody = document.querySelector("[basement-interactive]");
  }

  init() {
    window.addEventListener("load", () => {
      window.scrollTo(0, 0);
    });
    document.querySelector("body").classList.add("overflow-hidden");

    this.interactiveBody.addEventListener("click", (e) => {
      if (e.target.closest("[data-area]")) {
        const currentArea = e.target.closest("[data-area]");
        currentArea
          .querySelectorAll(".cd-image-container img")
          .forEach((image) => {
            image.setAttribute("src", image.dataset.src);
          });
        currentArea.querySelector(`.card-wrap`).classList.add("--show");
        document.querySelector("body").classList.add("overflow-hidden");
      }
      if (
        e.target.closest("[data-close-area]") ||
        (e.target.closest(".card-wrap.--show") && !e.target.closest(".card"))
      ) {
        const currentArea = e.target.closest("[data-area]");
        currentArea.querySelector(`.card-wrap`).classList.remove("--show");
        document.querySelector("body").classList.remove("overflow-hidden");
      }
      if (e.target.closest("#welcome button")) {
        const currentArea = e.target.closest("#welcome");
        currentArea.classList.add("hidden");
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    });

    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        document.querySelector(`.card-wrap`).classList.remove("--show");
        document.querySelector("body").classList.remove("overflow-hidden");
      }
    });

    // horizontal slider
    const scrollContainer = document.querySelector("[scroll-container]");
    const scrollProgress = document.querySelector("#scroll-progress");
    const screenWidth = window.innerWidth;
    let scrollPercentage = 0;

    updateProgressBar();

    scrollContainer.addEventListener("scroll", updateProgressBar);

    document.addEventListener("click", (e) => {
      if (e.target.closest("#scroll-left")) {
        scrollContainer.style.scrollBehavior = "smooth";
        scrollContainer.scrollLeft -= screenWidth;

        updateProgressBar();
      }
      if (e.target.closest("#scroll-right")) {
        scrollContainer.style.scrollBehavior = "smooth";
        scrollContainer.scrollLeft += screenWidth;

        updateProgressBar();
      }
    });

    function updateProgressBar() {
      if (
        scrollContainer.scrollLeft <= 0 ||
        scrollContainer.scrollLeft /
          (scrollContainer.scrollWidth - scrollContainer.clientWidth) <=
          screenWidth / scrollContainer.scrollWidth
      ) {
        scrollPercentage = (screenWidth / scrollContainer.scrollWidth) * 100;
      } else {
        scrollPercentage =
          (scrollContainer.scrollLeft /
            (scrollContainer.scrollWidth - scrollContainer.clientWidth)) *
          100;
      }

      scrollProgress.value = scrollPercentage;
    }

    // image comparison slider
    imageComparisonSliders();
    function imageComparisonSliders() {
      var dragging = false,
        scrolling = false,
        resizing = false;

      var imageComparisonContainers = document.querySelectorAll(
        ".cd-image-container"
      );
      checkPosition(imageComparisonContainers);

      window.addEventListener("scroll", function () {
        if (!scrolling) {
          scrolling = true;
          !window.requestAnimationFrame
            ? setTimeout(function () {
                checkPosition(imageComparisonContainers);
              }, 100)
            : requestAnimationFrame(function () {
                checkPosition(imageComparisonContainers);
              });
        }
      });

      imageComparisonContainers.forEach(function (container) {
        var actual = container;
        drags(
          actual.querySelector(".cd-handle"),
          actual.querySelector(".cd-resize-img"),
          actual
        );
      });

      window.addEventListener("resize", function () {
        if (!resizing) {
          resizing = true;
          !window.requestAnimationFrame
            ? setTimeout(function () {
                checkLabel(imageComparisonContainers);
              }, 100)
            : requestAnimationFrame(function () {
                checkLabel(imageComparisonContainers);
              });
        }
      });

      function checkPosition(containers) {
        containers.forEach(function (container) {
          var actualContainer = container;
          if (
            window.scrollY + window.innerHeight * 0.5 >
            actualContainer.offsetTop
          ) {
            actualContainer.classList.add("is-visible");
          }
        });

        scrolling = false;
      }

      function drags(dragElement, resizeElement, container) {
        function moveElement(e) {
          var touch = e.type === "touchstart" ? e.touches[0] : e;
          var offsetX =
            touch.clientX - dragElement.getBoundingClientRect().left;

          function drag(e) {
            var posX =
              e.type === "touchmove" ? e.touches[0].clientX : e.clientX;
            var containerOffsetLeft = container.getBoundingClientRect().left;
            var containerWidth = container.offsetWidth;
            var dragWidth = dragElement.offsetWidth;
            var minLeft = containerOffsetLeft + 10;
            var maxLeft = containerOffsetLeft + containerWidth - dragWidth - 20;
            var leftValue = posX - offsetX;

            if (leftValue < minLeft) {
              leftValue = minLeft;
            } else if (leftValue > maxLeft) {
              leftValue = maxLeft;
            }

            var widthValue =
              ((leftValue + dragWidth / 2 - containerOffsetLeft) * 100) /
                containerWidth +
              "%";

            dragElement.style.left = widthValue;
            resizeElement.style.width = widthValue;
            var leftPercentage = (leftValue + dragWidth / 2 - containerOffsetLeft) / containerWidth;
            var rightPercentage = 1 - leftPercentage;

            var beforeLabel = container.querySelector('.before');
            var afterLabel = container.querySelector('.after');
            var contAfetrBefore = container.querySelector('.bef-aft');

            if (leftPercentage < 0.2) {
              beforeLabel.style.display = 'none';
              contAfetrBefore.style.justifyContent = 'end';
            } else {
              beforeLabel.style.display = 'block';
              contAfetrBefore.style.justifyContent = 'space-between';
            }

            if (rightPercentage < 0.2) {
              afterLabel.style.display = 'none';
            } else {
              afterLabel.style.display = 'block';
            }
          }

          function stopDragging() {
            window.removeEventListener("mousemove", drag);
            window.removeEventListener("touchmove", drag);
            window.removeEventListener("mouseup", stopDragging);
            window.removeEventListener("touchend", stopDragging);
            dragging = false;
          }

          dragging = true;

          window.addEventListener("mousemove", drag);
          window.addEventListener("touchmove", drag);
          window.addEventListener("mouseup", stopDragging);
          window.addEventListener("touchend", stopDragging);
        }

        dragElement.addEventListener("mousedown", moveElement);
        dragElement.addEventListener("touchstart", moveElement);
      }

      function checkLabel(containers) {
        containers.forEach(function (container) {
          var actualContainer = container;
          var handlePos = parseFloat(
            container.querySelector(".cd-handle").style.left
          );
          var label = actualContainer.querySelector(
            '.cd-image-label[data-type="modified"]'
          );

          if (handlePos < 50 && label.classList.contains("is-visible")) {
            label.classList.remove("is-visible");
          } else if (
            handlePos >= 50 &&
            !label.classList.contains("is-visible")
          ) {
            label.classList.add("is-visible");
          }
        });

        resizing = false;
      }
    }

    // mobile cards nav
    document.addEventListener("click", (e) => {
      if (e.target.closest("[data-next]")) {
        const nextCardNumber = e.target.closest("[data-next]").dataset.next;
        const nextCard = document.querySelector(`.card-${nextCardNumber}`);
        if (!nextCard) return;
        nextCard.scrollTo(0, 0);
        nextCard
          .querySelectorAll(".cd-image-container img")
          .forEach((image) => {
            if (!image.hasAttribute("src")) {
              image.setAttribute("src", image.dataset.src);
            }
          });
        nextCard.parentElement.classList.add("--show");
        e.target.closest(".card").parentElement.classList.remove("--show");
      }
      if (e.target.closest("[data-prev]")) {
        const prevCardNumber = e.target.closest("[data-prev]").dataset.prev;
        const prevCard = document.querySelector(`.card-${prevCardNumber}`);
        if (!prevCard) return;
        prevCard.scrollTo(0, 0);
        prevCard
          .querySelectorAll(".cd-image-container img")
          .forEach((image) => {
            if (!image.hasAttribute("src")) {
              image.setAttribute("src", image.dataset.src);
            }
          });
        prevCard.parentElement.classList.add("--show");
        e.target.closest(".card").parentElement.classList.remove("--show");
      }
    });

    // mobile zoom
    const imageContainer = document.querySelector("[basement-interactive]");
    let currentZoomLevel = 1;
    const minContainerWidth = imageContainer.offsetWidth;
    const maxZooms = 3;

    document.addEventListener("click", (e) => {
      if (e.target.closest("#zoom-in")) {
        const scrollLeftWidth = scrollContainer.scrollLeft;
        const scrollTopWidth = scrollContainer.scrollTop;
        if (currentZoomLevel < maxZooms) {
          const { newWidth, newHeight } = getSizesForZoomIn(imageContainer);
          if (newWidth <= minContainerWidth * Math.pow(1.5, maxZooms)) {
            setNewSizes(imageContainer, newWidth, newHeight);
            scrollContainer.scrollBy({
              left: scrollLeftWidth * 1.5 - scrollLeftWidth,
              top: scrollTopWidth * 1.5 - scrollTopWidth,
              behavior: "smooth",
            });
            currentZoomLevel++;
            updateProgressBar();
          }
        }
      }
      if (e.target.closest("#zoom-out")) {
        const scrollLeftWidth = scrollContainer.scrollLeft;
        const scrollTopWidth = scrollContainer.scrollTop;
        const { newWidth, newHeight } = getSizesForZoomOut(imageContainer);
        if (newWidth >= minContainerWidth) {
          setNewSizes(imageContainer, newWidth, newHeight);
          scrollContainer.scrollBy({
            left: scrollLeftWidth / 1.5 - scrollLeftWidth,
            top: scrollTopWidth / 1.5 - scrollTopWidth,
            behavior: "smooth",
          });
          currentZoomLevel--;
          updateProgressBar();
        }
      }
    });

    function getSizesForZoomIn(imageContainer) {
      const { currentWidth, currentHeight } = getCurrentSizes(imageContainer);
      const newWidth = currentWidth * 1.5;
      const newHeight = currentHeight * 1.5;

      return { newWidth, newHeight };
    }

    function getSizesForZoomOut(imageContainer) {
      const { currentWidth, currentHeight } = getCurrentSizes(imageContainer);
      const newWidth = currentWidth / 1.5;
      const newHeight = currentHeight / 1.5;

      return { newWidth, newHeight };
    }

    function getCurrentSizes(imageContainer) {
      const currentWidth = parseInt(imageContainer.offsetWidth);
      const currentHeight = parseInt(imageContainer.offsetHeight);

      return { currentWidth, currentHeight };
    }

    function setNewSizes(imageContainer, newWidth, newHeight) {
      imageContainer.style.width = newWidth + "px";
      imageContainer.style.height = newHeight + "px";
    }

    
    function handleAreaClick(e) {
      if (e.target.closest("[data-area]")) {
        handleDataAreaClick(e);
      }
      if (
        e.target.closest("[data-close-area]") ||
        (e.target.closest(".card-wrap.--show") && !e.target.closest(".card"))
      ) {
        handleCloseAreaClick(e);
      }
      if (e.target.closest("#welcome button")) {
        handleWelcomeButtonClick(e);
      }
    }
    function handleAreaClickRemove(e) {
      if (e.target.closest("[data-area]")) {
        handleDataAreaClickRemove(e);
      }
      if (
        e.target.closest("[data-close-area]") ||
        (e.target.closest(".card-wrap.--show") && !e.target.closest(".card"))
      ) {
        handleCloseAreaClick(e);
      }
      if (e.target.closest("#welcome button")) {
        handleWelcomeButtonClick(e);
      }
    }
    
    function handleDataAreaClick(e) {
      const currentArea = e.target.closest("[data-area]");
      currentArea
        .querySelectorAll(".cd-image-container img")
        .forEach((image) => {
          image.setAttribute("src", image.dataset.src);
        });
      currentArea.querySelector(`.card-wrap`).classList.remove("zoom-design");
      currentArea.querySelector(`.card-wrap`).classList.add("--show");
      document.querySelector("body").classList.remove("overflow-hidden");
      if (e.target.closest("[data-next]")) {
        const nextCardNumber = e.target.closest("[data-next]").dataset.next;
        const nextCard = document.querySelector(`.card-${nextCardNumber}`);
       
        nextCard.parentElement.classList.add("--show");
        nextCard.parentElement.classList.remove("zoom-design");
        currentArea.querySelector(`.card-wrap`).classList.remove("--show");
        currentArea.querySelector(`.card-wrap`).classList.remove("zoom-design");
      }
      if (e.target.closest("[data-prev]")) {
        const prevCardNumber = e.target.closest("[data-prev]").dataset.prev;
        const prevCard = document.querySelector(`.card-${prevCardNumber}`); 
 
        prevCard.parentElement.classList.add("--show");
        prevCard.parentElement.classList.remove("zoom-design");
        currentArea.querySelector(`.card-wrap`).classList.remove("--show");
        currentArea.querySelector(`.card-wrap`).classList.remove("zoom-design");
      }
    }


    function handleDataAreaClickRemove(e) {
      
      const currentArea = e.target.closest("[data-area]");
      currentArea
        .querySelectorAll(".cd-image-container img")
        .forEach((image) => {
          image.setAttribute("src", image.dataset.src);
        });
      currentArea.querySelector(`.card-wrap`).classList.add("--show");
      document.querySelector("body").classList.add("overflow-hidden");
      currentArea.querySelector(`.card-wrap`).classList.add("zoom-design");
      if (currentArea.querySelector(`.card-wrap`).classList.contains("--show")) {
       document.getElementById("zoom-out").classList.add("hidden");
       document.getElementById("zoom-in").classList.add("hidden");
      }
      if (e.target.closest("[data-next]")) {
        const nextCardNumber = e.target.closest("[data-next]").dataset.next;
        const nextCard = document.querySelector(`.card-${nextCardNumber}`);
        
        nextCard.parentElement.classList.add("--show");
        nextCard.parentElement.classList.add("zoom-design");
        currentArea.querySelector(`.card-wrap`).classList.remove("--show");
        currentArea.querySelector(`.card-wrap`).classList.remove("zoom-design");
      }
      if (e.target.closest("[data-prev]")) {
        const prevCardNumber = e.target.closest("[data-prev]").dataset.prev;
        const prevCard = document.querySelector(`.card-${prevCardNumber}`);
        if (!prevCard) return;
        prevCard.scrollTo(0, 0);
        prevCard
          .querySelectorAll(".cd-image-container img")
          .forEach((image) => {
            if (!image.hasAttribute("src")) {
              image.setAttribute("src", image.dataset.src);
            }
          });
        prevCard.parentElement.classList.add("--show");
        prevCard.parentElement.classList.add("zoom-design");
        currentArea.querySelector(`.card-wrap`).classList.remove("--show");
        currentArea.querySelector(`.card-wrap`).classList.remove("zoom-design");
      }
    }
    
    function handleCloseAreaClick(e) {
      const currentArea = e.target.closest("[data-area]");
      currentArea.querySelector(`.card-wrap`).classList.remove("--show");
      currentArea.querySelector(`.card-wrap`).classList.remove("zoom-design");
      document.querySelector("body").classList.remove("overflow-hidden");
      document.getElementById("zoom-out").classList.remove("hidden");
      document.getElementById("zoom-in").classList.remove("hidden");
    }
    
    function handleWelcomeButtonClick(e) {
      const currentArea = e.target.closest("#welcome");
      currentArea.classList.add("hidden");
      document.querySelector("body").classList.remove("overflow-hidden");
    }

    document.addEventListener("DOMContentLoaded", function () {
      const imageContainer = document.querySelector("[basement-interactive]");
      const initialWidth = imageContainer.offsetWidth;
      const initialHeight = imageContainer.offsetHeight;
      const screenWidth = window.innerWidth;
      let currentZoomLevel = 1;
      const maxZooms = 3;
      let isZoomedIn = false;
      const contentBelow = document.querySelector(".circles-wrap");
      const baseInter = document.getElementById("baseInter");
      const scrollCont = document.getElementById("scrollCont");
      const hideScroll = document.getElementById("scroll-progress");
      const hideScrollright = document.getElementById("scroll-right");
      const hideScrollleft = document.getElementById("scroll-left");
      const mainBg = document.getElementById("main");
      const circles = document.querySelectorAll('.circle');
      
      document.addEventListener("click", (e) => {
          if (window.innerWidth < 768) {
              if (e.target.closest("#zoom-out")) {
                  if (currentZoomLevel === 1) {
                      zoomOutToScreenWidth(imageContainer, screenWidth);
                      hideContentBelow();
                      decreaseCircles(circles);
                  } else {
                      zoomOutToInitialSize(imageContainer, initialWidth, initialHeight);
                  }
                  updateProgressBar();
              }
              if (e.target.closest("#zoom-in")) {
                  if (currentZoomLevel === 1 && imageContainer.offsetWidth < initialWidth) {
                      zoomIn(imageContainer, initialWidth, initialHeight);
                      showContentBelow();
                      increaseCircles(circles); 
                  } else {
                    zoomInToOriginalSize(imageContainer, initialWidth, initialHeight);
                    increaseCircles(circles); 
                  }
                  updateProgressBar();
              }
          }
      });
  
      function zoomOutToInitialSize(imageContainer, initialWidth, initialHeight) {
        if (currentZoomLevel !== 1) {
            setNewSizes(imageContainer, initialWidth, initialHeight);
            currentZoomLevel = 1;
            document.addEventListener("click", handleAreaClick);
        }
      }
      
      function zoomOutToScreenWidth(imageContainer, screenWidth) {
          setNewSizes(imageContainer, screenWidth, initialHeight);
          currentZoomLevel = 1;
          document.removeEventListener("click", handleAreaClick);
          document.addEventListener("click", handleAreaClickRemove);

      }
  
      function zoomInToOriginalSize(imageContainer, initialWidth, initialHeight) {
        const newWidth = initialWidth * 1.5;
        const newHeight = initialHeight * 1.5;
        setNewSizes(imageContainer, newWidth, newHeight);
        currentZoomLevel++;
        document.addEventListener("click", handleAreaClick);
       }
      
      function zoomIn(imageContainer, initialWidth, initialHeight) {
          const newWidth = initialWidth;
          const newHeight = initialHeight;
          setNewSizes(imageContainer, newWidth, newHeight);
          currentZoomLevel = 1;
          document.addEventListener("click", handleAreaClick);
      }
      
      function setNewSizes(imageContainer, newWidth, newHeight) {
          imageContainer.style.width = `${newWidth}px`;
          imageContainer.style.height = `${newHeight}px`;        
      }

      //rotate tooltip for 12 elements
      const tooltips = document.querySelectorAll('.tooltip');
      const twelfthTooltip = tooltips[11];
      
      const transformTooltip = (element) => {
        element.style.transform = 'translateY(-25%) scaleX(-1)';
      };
      
      const transformHeadingAndParagraph = (tooltip) => {
        const heading = tooltip.querySelector('.custom-heading');
        const paragraph = tooltip.querySelector('.custom-paragraph');
        if (heading && paragraph) {
          transformTooltip(heading);
          transformTooltip(paragraph);
        }
      };
      
      if (twelfthTooltip) {
        transformTooltip(twelfthTooltip);
        twelfthTooltip.style.left = '-200px';
        transformHeadingAndParagraph(twelfthTooltip);
      }

      function hideContentBelow(disableScroll = true) {
          hideScroll.classList.add("hidden");
          hideScrollright.classList.add("hidden");
          hideScrollleft.classList.add("hidden");
          baseInter.classList.add("item-alignment");
          mainBg.classList.add("bg-customgrey");

          if (disableScroll) {
            document.body.style.overflow = "hidden"; 
        }
      }
  
      function showContentBelow(enableScroll = true) {
          hideScroll.classList.remove("hidden");
          hideScrollright.classList.remove("hidden");
          hideScrollleft.classList.remove("hidden");
          baseInter.classList.remove("item-alignment");
          mainBg.classList.remove("bg-customgrey");
          if (enableScroll) {
            document.body.style.overflow = "auto";
        }
      }

      // location of points when zooming
      function setCirclePosition(circle, position) {
        circle.style.right = position.right;
        circle.style.left = position.left;
        circle.style.top = position.top;
        circle.style.bottom = position.bottom;
        circle.style.height = position.height;
        circle.style.width = position.width;
        circle.style.borderWidth = position.borderWidth;
      }
      
      function decreaseCircles(circles) {
        
        const commonValues = { height: '1.75rem', width: '1.75rem', borderWidth: '3px'};
        const positions = [
          { right: '16.5%', top: '2.7%', ...commonValues },
          { left: '47.3%', top: '4.2%', ...commonValues },
          { right: '34.2%', top: '7.5%', ...commonValues },
          { left: '16.6%', top: '5%', ...commonValues },
          { right: '18.9%', top: '8.8%', ...commonValues },
          { left: '2.77%', bottom: '83.8%', ...commonValues },
          { right: '27%', bottom: '81%', ...commonValues },
          { left: '25.2%', bottom: '79.3%', ...commonValues },
          { left: '25.4%', bottom: '74%',  ...commonValues },
          { right: '19.3%', bottom: '74%', ...commonValues },
          { right: '40.3%', bottom: '75%', ...commonValues },
          { right: '7%', bottom: '82%', ...commonValues }
        ];
      
        circles.forEach((circle, index) => {
          setCirclePosition(circle, positions[index]);
        });
      }
      
      function increaseCircles(circles) {
        const commonValues = { height: '2.75rem', width: '2.75rem', borderWidth: '0.313rem' };
        const positions = [
          { right: '18.5%', top: '20.7%', ...commonValues },
          { left: '49.3%', top: '25.2%', ...commonValues },
          { right: '44.2%', top: '34.5%', ...commonValues },
          { left: '18.6%', top: '32%', ...commonValues },
          { right: '21.9%', top: '44.8%', ...commonValues },
          { left: '2.77%', bottom: '44.8%', ...commonValues },
          { right: '37%', bottom: '29%', ...commonValues },
          { left: '25.2%', bottom: '20.3%', ...commonValues },
          { left: '25.4%', bottom: '4%', ...commonValues },
          { right: '26.3%', bottom: '10%', ...commonValues },
          { right: '47.3%', bottom: '13%', ...commonValues },
          { right: '7%', bottom: '42%', ...commonValues }
        ];
      
        circles.forEach((circle, index) => {
          setCirclePosition(circle, positions[index]);
        });
      }
    });

    //behavior of arrows when viewing the result before - after
    const displacement = document.querySelectorAll('.cd-handle span');
    const displace = document.querySelectorAll('.cd-handle');
    const imageContainers = document.querySelectorAll('.cd-image-container img');
    const cardWrap = document.querySelectorAll('.card-wrap .card');
    let hiddenArrow = false;
    let mouseDownInsideHandle = false;
    let mouseDownOnSpan = false;

    function applyFilterToImages(filterValue) {
        imageContainers.forEach(function(img) {
          img.style.filter = filterValue;
        });
    }

    function hideArrows() {
      hiddenArrow = true;
      document.querySelectorAll('.arrow-7, .arrow-8, .bef-aft').forEach(function(arrow) {
        arrow.style.display = 'none';
      });
      cardWrap.forEach(function(card) {
        card.classList.add('overflow-hidden');
      });
      document.body.classList.add('overflow-hidden');
    }

    function showArrows() {
      hiddenArrow = false;
      document.querySelectorAll('.arrow-7, .arrow-8').forEach(function(arrow) {
        arrow.style.display = 'block';
      });
      document.querySelectorAll('.bef-aft').forEach(function(arrow) {
        arrow.style.display = 'flex';
      });
      cardWrap.forEach(function(card) {
        card.classList.remove('overflow-hidden');
      });
      document.body.classList.remove('overflow-hidden');
    }

    document.querySelectorAll('.cd-handle').forEach(function(handle) {
        handle.addEventListener('mousedown', function() {
            mouseDownInsideHandle = true;
            hideArrows();
        });

        handle.addEventListener('mouseup', function() {
            mouseDownInsideHandle = false;
            showArrows();
        });
    });

    displacement.forEach(function(span) {
        span.addEventListener('mousedown', function() {
            mouseDownOnSpan = true;
            hideArrows();
            applyFilterToImages('none');
        });

        span.addEventListener('touchstart', function() {
            mouseDownOnSpan = true;
            hideArrows();
            applyFilterToImages('none');
        });
    });
    displace.forEach(function(el) {
      el.addEventListener('mousedown', function() {
          mouseDownOnSpan = true;
          hideArrows();
          applyFilterToImages('none');
      });

      el.addEventListener('touchstart', function() {
          mouseDownOnSpan = true;
          hideArrows();
          applyFilterToImages('none');
      });
    });

    document.addEventListener('mouseup', function() {
        mouseDownOnSpan = false;
        showArrows();
    });

    document.addEventListener('touchend', function() {
        mouseDownOnSpan = false;
        showArrows();
    });

    document.querySelector('.cd-handle').addEventListener('mouseleave', function() {
        if (!hiddenArrow) showArrows();
    });

    document.querySelectorAll('.cd-image-container').forEach(function(container) {
        container.addEventListener('mouseover', function() {
          if (window.innerWidth >= 768) {
            if (!mouseDownOnSpan) {
              applyFilterToImages('brightness(0.6)');
            }
          }
        });

        container.addEventListener('mouseleave', function() {
            if (!mouseDownOnSpan) {
                applyFilterToImages('none');
            }
        });
    })
    
    //product description in popup

    const btnMore = document.querySelectorAll('.btn-more');
    const btnLess = document.querySelectorAll('.btn-less');
    if (window.innerWidth >= 768) {
      btnMore.forEach(function(button, index) {
        button.addEventListener('click', function() {
          const lessBtn = btnLess[index];
          lessBtn.classList.remove('md:hidden');
          let container = this.closest('.description-container');
          if (container.classList.contains('expanded')) {
              container.classList.remove('expanded');
          } else {
              container.classList.add('expanded');
              button.style.display = 'none';
          }
        });
      });

      btnLess.forEach(function(button) {
        button.addEventListener('click', function() {
          const container = this.closest('.description-container');
          container.classList.remove('expanded');
          const moreButton = container.querySelector('.btn-more');
          moreButton.style.display = ''; 
          button.classList.add('md:hidden');
        });
      });
    }
  }
}
