import {
    Swiper,
    Navigation,
    A11y
} from 'swiper/swiper.esm.js';

Swiper.use([Navigation, A11y]);

import Component from "../../../base/Component";

export default class FeaturedResource extends Component {
    constructor(props) {
        super(props)
        this.slider = document.querySelector('[data-featresource-slider]');
    }

    init() {
        new Swiper(this.slider.querySelector('.swiper-container'), {
            touchRatio: 1,
            slidesPerView: 1,
            spaceBetween: 10,
            centeredSlidesBounds: true,
            centerInsufficientSlides: true,
            centeredSlides: false,
            initialSlide: 1,
            loop: true,
            navigation: {
                nextEl: '.featresource-slider__control-next',
                prevEl: '.featresource-slider__control-prev',
            },
            // Responsive breakpoints
            breakpoints: {
                400: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 4,
                    centeredSlides: true,
                    initialSlide: 2,
                    spaceBetween: 20,
                },
                // 1280: {
                //     slidesPerView: 4,
                //     centeredSlides: true,
                //     initialSlide: 2,
                //     spaceBetween: 40
                // }
            }
        });
    }
}

