import Component from "../../base/Component";

export default class LocatorActionButton extends Component {
  constructor(props) {
    super(props);
    this.form = document.querySelector("[locator-form]");
  }

  init() {
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();

      const zipInput = this.form.querySelector("input").value.trim();
      const category = this.form.querySelector("input").dataset.category;

      if (zipInput !== "") {
        this.form.querySelector("button").setAttribute("disabled", "");
        const url = `${window.location.origin}/where-to-buy?near=${zipInput}&product=${category}`;
        window.location.href = url;
      } else {
        this.form.querySelector(".text-error").classList.remove("hidden");
      }
    });
  }
}
