'use strict';

import Alpine from 'alpinejs'
import Client from '../shopify/shopify-sdk-fork'
// import Client from 'shopify-buy'
import jsCookie from 'js-cookie'
import {gtmEcomm} from "../utils/gtmEcomm";

import Component from '../base/Component';

export default class Cart extends Component {

    private client: Client.Client;
    public cart: Client.Cart | null;
    private currentSiteHandle: string;

    constructor(props: object = {}) {
        super(props)

        const shopifyUrlElement: HTMLMetaElement | null = document.head.querySelector('meta[name="shopify-site-url"]');
        const shopifyStorefrontTokenElement: HTMLMetaElement | null = document.head.querySelector('meta[name="shopify-storefront-token"]');
        const siteHandleElement: HTMLMetaElement | null = document.head.querySelector('meta[name="site-handle"]');

        const shopifyConfig: Client.Config = {
            domain: '',
            storefrontAccessToken: ''
        }

        shopifyConfig.domain = shopifyUrlElement ? shopifyUrlElement.content : '';
        shopifyConfig.storefrontAccessToken = shopifyStorefrontTokenElement ? shopifyStorefrontTokenElement.content : '';
        this.currentSiteHandle = siteHandleElement ? siteHandleElement.content : 'ugl';

        this.client = Client.buildClient(shopifyConfig);
        this.cart = null;
    }

    async init() {
        const self = this
        Alpine.store('cart', {
            open: false,
            cart: {
                lineItems: []
            },

            async init() {
                const existingCartId = jsCookie.get('cart');
                if (existingCartId) {
                    let cartResponse = null;
                    try {
                        console.log('fetching cart')
                        cartResponse = await self.client.checkout.fetch(existingCartId);
                        this.handleCartResponse(cartResponse);
                    } catch (e) {
                        console.log('Failed to get cart', e)
                    }
                }
                if (self.cart === null) {
                    console.log('Creating new cart');
                    this.handleCartResponse(await self.client.checkout.create())
                    jsCookie.set('cart', self.cart.id, {expires: 30})
                    const attributes = {
                        customAttributes: [{key: 'sourceSiteHandle', value: self.currentSiteHandle}]
                    }
                    this.handleCartResponse(await self.client.checkout.updateAttributes(self.cart.id, attributes));
                    console.log('Created new cart');
                }
            },

            async removeItem(item) {
                const index = this.cart.lineItems.findIndex((el) => el.id === item.id)
                if (index !== -1) {
                    this.cart.lineItems[index].deleting = true
                }
                const qty = this.cart.lineItems[index].qty
                this.handleCartResponse(await self.client.checkout.removeLineItems(self.cart.id, [item.id]))
                gtmEcomm.removeFromCart(item.variant.id, qty)
            },

            async add(item, quantity = 1, $event = null, options = {}) {
                console.debug('Adding item to cart', { item, quantity })
                if ($event && $event.target) {
                    $event.target.classList.add('loading')
                }
                this.handleCartResponse(await self.client.checkout.addLineItems(self.cart.id, [
                    {
                        variantId: btoa(item.admin_graphql_api_id),
                        quantity: quantity
                    }
                ]))
                gtmEcomm.addToCart(item.admin_graphql_api_id, quantity)

                if ($event && $event.target) {
                    $event.target.classList.remove('loading')
                }

                this.open = true
            },

            checkout() {
                window.location.href = self.cart.webUrl
            },


            handleCartResponse(cart: Client.Cart) {
                console.debug('Cart response', cart)
                if(cart.completedAt !== null) {
                    console.log('Existing cart already completed.')
                    self.cart = null;
                    return;
                }
                self.cart = cart
                console.debug('Starting cart:', this.cart)
                this.cart = JSON.parse(JSON.stringify(cart))
                console.debug('Ending cart:', this.cart)
                console.debug(this.cart)
            },
        })
    }

}